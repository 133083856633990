import {Injectable} from '@angular/core';
import {ConverterService, Occ, OccProductSearchPageNormalizer, PRODUCT_NORMALIZER} from '@spartacus/core';
import {KurzFacet, KurzFacetValue, KurzProductSearchPage} from '../kurz-components/kurz-product-list/kurz-product-list.service';
import {KurzProduct} from '../kurz-components/shared/types/kurz-product.interface';
import {getUrlOfSingleMedia} from '@util/functions/strings';


@Injectable({ providedIn: 'root' })
export class KurzOccProductSearchPageNormalizer extends OccProductSearchPageNormalizer {

  private NESTED_CHAR = '/';

  private _converterService: ConverterService;

  constructor(converterService: ConverterService) {
    super(converterService);
    this._converterService = converterService;
  }

  override convert(source: Occ.ProductSearchPage, target: KurzProductSearchPage = {}): KurzProductSearchPage {
    target = {
      ...target,
      ...(source as any),
    };

    if (target.facets) {
      this.convertFacetsResponse(target);
    }

    if (source.products) {
      target.products = source.products.map((product) =>
        this._converterService.convert(product, PRODUCT_NORMALIZER)
      ) as KurzProduct[];
    }

    return target;
  }

  private convertFacetsResponse(target: KurzProductSearchPage): KurzProductSearchPage {

    const buildNestedStructureRec = (valueList: KurzFacetValue[], facetRef: KurzFacet, parentValueRef: KurzFacetValue) => {

      const recursiveCandidates: KurzFacetValue[] = [];
      let lastValue: KurzFacetValue;

      let i: number;
      let value: KurzFacetValue;

      for (i = 0; i < valueList.length; i++) {
        value = valueList[i];
        value.facetRef = facetRef;
        value.parent = parentValueRef;

        value.imageUrl = this.checkCorrectFacetImageUrl(value.imageUrl);

        if (lastValue && value.name.startsWith(lastValue.name + this.NESTED_CHAR)) {
          // current value is Child

          // get its actual name and set it
          const actualName = value.name.slice(lastValue.name.length + this.NESTED_CHAR.length);
          value.name = actualName;

          // remove it from the current facet value list
          const tmp = valueList.splice(i, 1)[0];

          // now we removed an element from the list so the index of the next element has decreased by 1
          // to accomodate, we have to decrease i by 1 as well (the reason why we use the for-loop)
          i--;

          // added to the children of the lastValue
          lastValue.children ||= [];
          lastValue.children.push(tmp);

          // if the candidate list is empty or the lastValue is not already on the candidate list then put it on there (assumption: it can only be the last element if it is on the list)
          if (recursiveCandidates.length === 0 || recursiveCandidates.length && recursiveCandidates[recursiveCandidates.length - 1] !== lastValue) {
            recursiveCandidates.push(lastValue);
          }

        } else if (facetRef.code === 'kurzFinishingProcess' && value.name.includes('/') && !valueList[i+1]?.name.startsWith(value.name)) {
          // this condition should only hit apply for finishingProcesses
          // finishingProcesses don't contain a parent facet, so we need to create a fake one and insert it at the current index
          const fakeFacet: KurzFacetValue = Object.assign({}, value, { name: value.name.split('/')[0] });
          fakeFacet.hierarchicalParent = true;
          fakeFacet.count = 0;
          fakeFacet.selected = false;
          valueList.splice(i, 0, fakeFacet);

          lastValue = fakeFacet;
        } else {
          // value is not a child but keep it under surveillance. It may be a value which has children
          // we can determine that in the next loop
          lastValue = value;
        }

        // if facet value is selected then there is a breadcrumb, which might need to be corrected
        if (value.selected && value.parent) {
          this.correctBreadcrumbName(value, target);
        }

      }

      recursiveCandidates.forEach(value => buildNestedStructureRec(value.children, facetRef, value));

    };

    target.facets.forEach(facet => {
      buildNestedStructureRec(facet.values, facet, null);
    });

    return target;
  }

  private correctBreadcrumbName(value: KurzFacetValue, target: KurzProductSearchPage) {

    // not for finishing processes
    if (value.facetRef.code === 'kurzFinishingProcess') {
      return;
    }

    const prefixNameParts: string[] = [];
    let tempValue = value;

    while (tempValue.parent) {
      prefixNameParts.push(tempValue.parent.name);
      tempValue = tempValue.parent;
    }

    const prefix = prefixNameParts.reverse().join(this.NESTED_CHAR);
    const facetValueName = (prefix ? (prefix + this.NESTED_CHAR) : '') + value.name;

    const found = target.breadcrumbs.find(crumb => crumb.facetValueName === facetValueName);

    if (found) {
      found.facetValueName = value.name;
    }

  }

  private checkCorrectFacetImageUrl(imageUrl: KurzFacetValue['imageUrl']): string {
    if (!imageUrl) {
      return '';
    }
    if (!imageUrl.startsWith('http') && !imageUrl.startsWith('data')) {
      imageUrl = getUrlOfSingleMedia(imageUrl);
    }
    return imageUrl;
  }

}

export class KurzOccProductSearchPageTestingNormalizer {
  convert(source: Occ.ProductSearchPage, target: KurzProductSearchPage = {}): KurzProductSearchPage { // NOSONAR
    return target; // NOSONAR
  }
}
